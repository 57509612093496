<template>
  <article class="d-flex flex-column pt-9 px-5">
    <h1
      v-html="$t('leaflet.information.title')"
      class="text-center text-uppercase"
    ></h1>
    <p class="ma-5 text-center" v-html="$t('leaflet.information.subtitle')"></p>
    <div class="leaflet-information-wrapper d-flex flex-wrap justify-center">
      <div
        class="d-flex flex-column align-center flex-md-row w-100 mb-5 mb-md-0"
      >
        <div class="leaflet-information"></div>
        <div
          class="mb-1 mx-8 leaflet-information has-icon d-flex justify-center align-center"
        >
          <v-icon x-large color="white" class="pa-5">icon-sfoglia</v-icon>
        </div>
        <div
          class="my-3 my-md-0 leaflet-information d-flex justify-start align-center"
        >
          <div
            class="counter counter-left d-flex flex-column justify-start align-center align-md-start"
          >
            <h3 class="text-uppercase font-weight-bold">
              {{ $t("leaflet.navigation.browse.title") }}
            </h3>
            <span class="leaflet-information-text text-center text-md-start">{{
              $t("leaflet.navigation.browse.description")
            }}</span>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column align-center flex-md-row w-100 mb-5 mb-md-0"
      >
        <div
          class="my-3 my-md-0 leaflet-information d-flex justify-end align-center"
        >
          <div
            class="counter counter-right d-flex flex-column justify-end align-center align-md-end"
          >
            <h3 class="text-center text-md-end text-uppercase font-weight-bold">
              {{ $t("leaflet.navigation.search.title") }}
            </h3>
            <span class="leaflet-information-text text-center text-md-end">{{
              $t("leaflet.navigation.search.description")
            }}</span>
          </div>
        </div>
        <div
          class="mb-1 mx-8 leaflet-information has-icon d-flex justify-center align-center"
        >
          <v-icon x-large color="white" class="pa-5">icon-search</v-icon>
        </div>
        <div class="leaflet-information"></div>
      </div>

      <div
        class="d-flex flex-column align-center flex-md-row w-100 mb-5 mb-md-0"
      >
        <div class="leaflet-information"></div>
        <div
          class="mb-1 mx-8 leaflet-information has-icon d-flex justify-center align-center"
        >
          <v-icon x-large color="white" class="pa-5">icon-reparti</v-icon>
        </div>
        <div
          class="my-3 my-md-0 leaflet-information d-flex justify-start align-center"
        >
          <div
            class="counter counter-left d-flex flex-column justify-start align-center align-md-start"
          >
            <h3 class="text-uppercase font-weight-bold">
              {{ $t("leaflet.navigation.departments.title2") }}
            </h3>
            <span class="leaflet-information-text text-center text-md-start">{{
              $t("leaflet.navigation.departments.description")
            }}</span>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column align-center flex-md-row w-100 mb-5 mb-md-0"
      >
        <div
          class="my-3 my-md-0 leaflet-information d-flex justify-end align-center"
        >
          <div
            class="counter counter-right d-flex flex-column align-center align-md-end"
          >
            <h3 class="text-center text-md-end text-uppercase font-weight-bold">
              {{ $t("leaflet.navigation.zoom.title") }}
            </h3>
            <span class="leaflet-information-text text-center text-md-end">{{
              $t("leaflet.navigation.zoom.description")
            }}</span>
          </div>
        </div>
        <div
          class="mb-1 mx-8 leaflet-information has-icon d-flex justify-center align-center"
        >
          <v-icon x-large color="white" class="pa-5">icon-unfold</v-icon>
        </div>
        <div class="leaflet-information"></div>
      </div>

      <div
        class="d-flex flex-column align-center flex-md-row w-100 mb-5 mb-md-0"
      >
        <div class="leaflet-information"></div>
        <div
          class="mb-1 mx-8 leaflet-information has-icon d-flex justify-center align-center"
        >
          <v-icon x-large color="white" class="pa-5">icon-scarica</v-icon>
        </div>
        <div
          class="my-3 my-md-0 leaflet-information d-flex justify-start align-center"
        >
          <div
            class="counter  counter-left d-flex flex-column align-center align-md-start"
          >
            <h3 class="text-center text-md-end text-uppercase font-weight-bold">
              {{ $t("leaflet.navigation.download.title") }}
            </h3>
            <span class="leaflet-information-text text-center text-md-start">{{
              $t("leaflet.navigation.download.description")
            }}</span>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column align-center flex-md-row w-100 mb-3 mb-md-0"
      >
        <div
          class="my-3 my-md-0 leaflet-information d-flex justify-end align-center"
        >
          <div
            class="counter counter-right d-flex flex-column align-center align-md-end"
          >
            <h3 class="text-center text-md-end text-uppercase font-weight-bold">
              {{ $t("leaflet.navigation.shoppingList.title") }}
            </h3>
            <span class="leaflet-information-text text-center text-md-end">{{
              $t("leaflet.navigation.shoppingList.description")
            }}</span>
          </div>
        </div>
        <div
          class="mb-1 mx-8 leaflet-information has-icon d-flex justify-center align-center"
        >
          <v-icon x-large color="white" class="pa-5">icon-lista</v-icon>
        </div>
        <div class="leaflet-information"></div>
      </div>
    </div>
    <footer class="ma-5">
      <p class="text-center" v-html="$t('leaflet.information.footer')"></p>
    </footer>
  </article>
</template>

<script>
export default {
  name: "LeafletInformationModal"
};
</script>
