var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"leafletViewer",staticClass:"leaflet-viewer-container disable-zoom-on-safari grey lighten-3 mt-1",staticStyle:{"position":"relative"}},[_c('swiper',{ref:"swiper",class:{ fullscreen: _vm.fullscreen },staticStyle:{"padding":"0px 0px 40px"},style:({
      maxWidth: _vm.fullscreen ? '' : _vm.swiperHeight
    }),attrs:{"options":_vm.swiperOptions},on:{"slideChange":_vm.slideChanged}},_vm._l((_vm.leaflet.leafletPage),function(page){return _c('swiper-slide',{key:page.leafletPageId},[_c('div',{staticClass:"leaflet-page-container"},[_c('div',{staticClass:"imgage-container py-2 py-md-6",style:({
            maxHeight: _vm.swiperHeight,
            height: _vm.fullscreen ? _vm.swiperHeight : 'auto'
          })},[_c('div',{staticClass:"swiper-zoom-container",style:({
              maxHeight: _vm.fullscreen ? 'auto' : _vm.swiperHeight
            })},[_c('img',{staticClass:"image",attrs:{"src":`${_vm.leaflet.baseLocation}${page.imageLocation}`}}),_vm._l((page.leafletArea),function(area){return _c('div',{key:area.leafletAreaId,staticClass:"draggable",style:({
                left: area.x + '%',
                top: area.y + '%',
                width: area.w + '%',
                height: area.h + '%'
              }),on:{"click":function($event){$event.stopPropagation();return _vm.openDetail(
                  area.leafletAreaId,
                  area.leafletAreaTypeId,
                  area.metadata
                )}}},[(
                  area.metadata &&
                    area.metadata.leafletareatype_product &&
                    area.metadata.leafletareatype_product.ICON_TYPE
                )?_c('v-icon',{staticClass:"leaflet-icon pulse",class:area.metadata.leafletareatype_product.ICON_POSITION,attrs:{"small":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.openDetail(
                    area.leafletAreaId,
                    area.leafletAreaTypeId,
                    area.metadata,
                    area.metadata.leafletareatype_product.ICON_TYPE
                  )}}},[_vm._v(" icon-"+_vm._s(area.metadata.leafletareatype_product.ICON_TYPE)+" ")]):_vm._e()],1)})],2)])])])}),1),_c('div',{class:{ 'hide-swiper-navigation': _vm.fullscreen }},[_c('div',{staticClass:"swiper-button-next",attrs:{"id":`leaflet-button-next-${_vm.fullscreen}`}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("icon-arrow-forward")])],1),_c('div',{staticClass:"swiper-button-prev",attrs:{"id":`leaflet-button-prev-${_vm.fullscreen}`}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("icon-arrow-back")])],1),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination","id":`swiper-pagination-${_vm.fullscreen}`},slot:"pagination"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }