<template>
  <div
    class="img-container-leaflet d-flex flex-column justify-center align-center"
    style="position:relative"
  >
    <div>
      <img
        class="image image-leaflet"
        :src="`${areaDetail.metadata.leafletareatype_cta.IMAGE.location}`"
      />
      <v-tooltip left :key="heartKey">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click.stop.prevent="addProductToLeafletList()"
            @mousedown.stop
            color="primary"
            aria-label="Aggiungi alla lista della spesa"
            class="pb-1 add-to-list"
          >
            <v-icon large>icon-lista</v-icon>
            <v-icon small class="mt-6 plus-icon">$plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("product.addToList") }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<style lang="scss">
.image-leaflet {
  max-width: 100%;
  max-height: 500px;
}
.actions {
  margin-top: -25px;
}
.plus-icon {
  height: 20px !important;
  width: 20px !important;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
  color: $white !important;
  border-radius: 50%;
  background-color: var(--v-primary-base);
}
.add-to-list {
  position: absolute;
  top: 50px;
  right: 14px;
}
</style>
<script>
import { mapActions } from "vuex";

import get from "lodash/get";
export default {
  name: "CTADetailLeaflet",
  props: {
    areaDetail: { type: Object, required: true },
    leaflet: { type: Object, required: true },
    position: { type: Number, default: undefined },
    popup: { type: Boolean, default: true },
    icon: { type: String, required: false },
    products: { type: Array, required: false }
  },
  components: {},
  mixins: [],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      key: 0,
      tab: 0,
      product: null,
      heartKey: 0
    };
  },
  methods: {
    ...mapActions({ addToList: "custom/addToLeafletList" }),
    addProductToLeafletList() {
      console.log(this.products);
      console.log(this.areaDetail);

      if (get(this.areaDetail, "metadata.leafletareatype_cta.NAME")) {
        this.products[0].name = get(
          this.areaDetail,
          "metadata.leafletareatype_cta.NAME"
        );
      }
      if (get(this.areaDetail, "metadata.leafletareatype_cta.SHORT_DESCR")) {
        this.products[0].shortDescr = get(
          this.areaDetail,
          "metadata.leafletareatype_cta.SHORT_DESCR"
        );
      }
      if (get(this.areaDetail, "metadata.leafletareatype_cta.DESCR")) {
        this.products[0].description = get(
          this.areaDetail,
          "metadata.leafletareatype_cta.DESCR"
        );
      }
      if (
        get(this.areaDetail, "metadata.leafletareatype_cta.IMAGE.location") &&
        this.products[0] &&
        this.products[0].media &&
        this.products[0].media[0].small
      ) {
        this.products[0].media[0].small = get(
          this.areaDetail,
          "metadata.leafletareatype_cta.IMAGE.location"
        );
      }
      this.addToList({ leaflet: this.leaflet, product: this.products[0] });
    }
  },
  mounted() {}
};
</script>
