<template>
  <div
    ref="leafletViewer"
    class="leaflet-viewer-container disable-zoom-on-safari grey lighten-3 mt-1"
    style="position:relative;"
  >
    <swiper
      :options="swiperOptions"
      style="padding: 0px 0px 40px;"
      @slideChange="slideChanged"
      ref="swiper"
      :class="{ fullscreen: fullscreen }"
      :style="{
        maxWidth: fullscreen ? '' : swiperHeight
      }"
    >
      <swiper-slide
        v-for="page in leaflet.leafletPage"
        :key="page.leafletPageId"
      >
        <div class="leaflet-page-container">
          <div
            class="imgage-container py-2 py-md-6"
            :style="{
              maxHeight: swiperHeight,
              height: fullscreen ? swiperHeight : 'auto'
            }"
          >
            <div
              class="swiper-zoom-container"
              :style="{
                maxHeight: fullscreen ? 'auto' : swiperHeight
              }"
            >
              <img
                class="image"
                :src="`${leaflet.baseLocation}${page.imageLocation}`"
              />

              <div
                v-for="area in page.leafletArea"
                :key="area.leafletAreaId"
                class="draggable"
                @click.stop="
                  openDetail(
                    area.leafletAreaId,
                    area.leafletAreaTypeId,
                    area.metadata
                  )
                "
                :style="{
                  left: area.x + '%',
                  top: area.y + '%',
                  width: area.w + '%',
                  height: area.h + '%'
                }"
              >
                <v-icon
                  @click.stop="
                    openDetail(
                      area.leafletAreaId,
                      area.leafletAreaTypeId,
                      area.metadata,
                      area.metadata.leafletareatype_product.ICON_TYPE
                    )
                  "
                  v-if="
                    area.metadata &&
                      area.metadata.leafletareatype_product &&
                      area.metadata.leafletareatype_product.ICON_TYPE
                  "
                  small
                  color="white"
                  class="leaflet-icon pulse"
                  :class="area.metadata.leafletareatype_product.ICON_POSITION"
                >
                  icon-{{ area.metadata.leafletareatype_product.ICON_TYPE }}
                </v-icon>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div :class="{ 'hide-swiper-navigation': fullscreen }">
      <div :id="`leaflet-button-next-${fullscreen}`" class="swiper-button-next">
        <v-icon color="primary">icon-arrow-forward</v-icon>
      </div>
      <div :id="`leaflet-button-prev-${fullscreen}`" class="swiper-button-prev">
        <v-icon color="primary">icon-arrow-back</v-icon>
      </div>
      <!-- <div class="swiper-scrollbar"></div> -->
      <div
        :id="`swiper-pagination-${fullscreen}`"
        class="swiper-pagination"
        slot="pagination"
      ></div>
    </div>
  </div>
</template>
<style lang="scss">
.disable-zoom-on-safari {
  touch-action: pan-y; /* Enable horizontal and vertical panning */
}
.hide-swiper-navigation {
  display: none;
}

.fullscreen {
  .draggable {
    display: none;
  }
}
.fullscreen {
  .leaflet-container {
    .swiper-slide img {
      height: 100%;
    }
  }
}
.leaflet-container {
  .leaflet-icon {
    width: 18px;
    height: 18px;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);

    padding: 6px;
    border: 2px solid white;
    border-radius: 50%;
    background-color: var(--v-primary-base);
    &.icon-play {
      padding: 6px 6px 6px 7px;
    }
    &::before {
      font-size: 12px;
    }
    // box-shadow: 3px 3px 5px 0px #000000;
    &.up_sx {
      width: 18px;
      height: 18px;
      left: -15px;
      top: -15px;
    }
    &.up_dx {
      width: 18px;
      height: 18px;
      right: -15px;
      top: -15px;
    }
    &.up_ce {
      width: 18px;
      height: 18px;
      left: 50%;
      transform: translateX(-50%);
      top: -15px;
    }
    &.lo_sx {
      width: 18px;
      height: 18px;
      left: -15px;
      bottom: -15px;
    }
    &.lo_dx {
      width: 18px;
      height: 18px;
      nav-right: -15px;
      bottom: -15px;
    }
    &.lo_ce {
      width: 18px;
      height: 18px;
      left: 50%;
      transform: translateX(-50%);
      bottom: -15px;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: lightgray;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide-active {
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .swiper-slide-next {
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .swiper-slide img {
    display: block;
    max-width: 100%;
    // height: 100%;
    object-fit: cover;
  }

  @media only screen and (min-width: 769px) {
    .swiper-slide:first-child {
      transition: transform 100ms;
    }

    .swiper-slide:first-child img {
      transition: box-shadow 500ms;
    }

    .swiper-slide.swiper-slide-active:first-child {
      transform: translateX(50%);
      z-index: 2;
    }

    .swiper-slide.swiper-slide-active:first-child img {
      box-shadow: 0px 32px 80px rgba(0, 0, 0, 0.35);
    }

    .swiper-slide:nth-child(2) {
      transition: transform 100ms;
    }

    .swiper-slide.swiper-slide-next:nth-child(2) {
      transform: translateX(55%);
      z-index: 1;
    }

    .swiper[dir="rtl"] .swiper-slide.swiper-slide-active:first-child {
      transform: translateX(-50%);
    }

    .swiper[dir="rtl"] .swiper-slide.swiper-slide-next:nth-child(2) {
      transform: translateX(-55%);
    }
  }

  .category-leaflet-btn {
    .v-btn__content {
      flex-direction: column;
      .category-block-title {
        font-size: 10px;
        font-weight: normal;
        text-transform: none;
        white-space: initial;
        max-width: 70px;
      }
    }
  }
  .leaflet-page-container {
    position: relative;
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: "" !important;
  }

  .draggable {
    cursor: pointer;
    position: absolute;
    background-color: transparent;
    // background-color: rgba(255, 221, 2, 0.35);
    border: 1px solid transparent;
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      transition: all 0.3s;
      .leaflet-icon {
        opacity: 1;
      }
    }
  }

  .image {
    max-width: 100%;
    max-height: 100%;
    // height: 100%;
  }
  .swiper-pagination {
    position: absolute;
    bottom: 3px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 960px) {
  .leaflet-container {
    .leaflet-icon {
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.pulse {
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 221, 2, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
.fade_in_out {
  animation: fadeinout;
  animation-duration: 0s;
  animation-duration: 3s;
}
@keyframes fadeinout {
  from {
    background-color: rgba(255, 221, 2, 1);
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  to {
    opacity: 0.6;
  }
}
</style>
<script>
export default {
  // eslint-disable-next-line vue/no-unused-components
  name: "LeafletViewer",
  data() {
    return {
      swiperOptions: {
        activeIndex: this.activeIndex,
        observer: true,
        observeParents: true,
        lazy: true,
        slidesPerView: 1,
        allowTouchMove: true,
        zoom: false,
        centeredSlides: false,
        slidesPerGroupSkip: 1,
        grabCursor: true,
        maxRatio: 5,
        keyboard: {
          enabled: true
        },
        breakpoints: {
          769: {
            slidesPerView: this.fullscreen ? 1 : 2,
            slidesPerGroup: this.fullscreen ? 1 : 2,
            pagination: {
              el: `#swiper-pagination-${this.fullscreen}`,
              type: "custom",
              renderCustom: function(swiper) {
                const activeIndex = swiper.activeIndex + 1;
                const nextIndex = activeIndex == 1 ? null : activeIndex + 1;
                return (
                  "<span class='pagination-value'>Pagina <b>" +
                  activeIndex +
                  (nextIndex ? ", " + nextIndex : "") +
                  "</b> di " +
                  swiper.slides.length +
                  "</span>"
                );
              }
            }
          }
        },
        pagination: {
          el: `#swiper-pagination-${this.fullscreen}`,
          type: "custom",
          renderCustom: function(swiper, current, total) {
            return "pagina " + current + " di " + total;
          }
        },
        navigation: {
          nextEl: `#leaflet-button-next-${this.fullscreen}`,
          prevEl: `#leaflet-button-prev-${this.fullscreen}`
        }
      }
    };
  },
  props: {
    leaflet: { type: Object, required: true },
    fullscreen: { type: Boolean, default: false },
    swiperHeight: { type: String, default: "auto" },
    zoom: {
      type: Boolean,
      default: false
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    async openDetail(leafletAreaId, leafletAreaTypeId, metadata, iconType) {
      this.$emit("openDetail", {
        leafletAreaId: leafletAreaId,
        leafletAreaTypeId: leafletAreaTypeId,
        metadata: metadata,
        iconType: iconType
      });
    },
    async slideChanged() {
      this.$emit("slideChanged", this.$refs.swiper.$swiper.activeIndex);
      // console.log("LeafletViewer:", this.$refs.swiper.$swiper.activeIndex);
    },

    /*doubleTap() {
      if (!this.zoom) {
        return;
      }
      const swiperZoomInstance = this.$refs.swiper.$swiper;
      swiperZoomInstance.zoom.toggle();
    },*/

    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        console.log(entries);
      }
      if (entries[0].intersectionRatio == 100) {
        console.log(entries[0].target);
      }
    },
    preventZoom(event) {
      // Prevent pinch gestures that trigger zoom
      if (event.ctrlKey) {
        event.preventDefault();
      }
    }
  },
  watch: {
    fullscreen(value) {
      this.$nextTick(() => {
        if (value) {
          console.log(value);
          this.$refs.swiper.$swiper.zoom.enable();
          this.$refs.swiper.$swiper.mousewheel.enable();
        } else {
          console.log(value);
          this.$refs.swiper.$swiper.zoom.disable();
          this.$refs.swiper.$swiper.mousewheel.disable();
          this.$refs.swiper.$swiper.zoom.out();
        }
      });
    }
  },
  mounted() {
    this.$refs.swiper.$swiper.slideTo(this.activeIndex);
    this.zoom
      ? this.$refs.swiper.$swiper.zoom.enable()
      : this.$refs.swiper.$swiper.zoom.disable();
    /*
     *Removes a Safari default pinch/zoom behaviour.
     */
    window.addEventListener("touchstart", this.preventZoom, {
      passive: false
    });
    window.addEventListener("gesturestart", this.preventZoom, {
      passive: false
    });

    window.addEventListener("gesturechange", this.preventZoom, {
      passive: false
    });

    window.addEventListener("gestureend", this.preventZoom, {
      passive: false
    });

    window.addEventListener("touchend", this.preventZoom, {
      passive: false
    });

    /*
     *Removes a Chrome and Firefox default pinch/zoom behaviour.
     */
    window.addEventListener("wheel", this.preventZoom, { passive: false });
  },
  beforeDestroy() {
    window.removeEventListener("touchstart", this.preventZoom);
    window.removeEventListener("gesturestart", this.preventZoom);
    window.removeEventListener("gesturechange", this.preventZoom);
    window.removeEventListener("gestureend", this.preventZoom);
    window.removeEventListener("touchend", this.preventZoom);
    window.removeEventListener("wheel", this.preventZoom);
  }
};
</script>
